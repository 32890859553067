<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" v-if="roleName !== '干线公路'">
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目法人单位">
                                        <el-input style="width: 160px" size="medium" v-model="frdw" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目类别">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmlb" :options="xmlbList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设规模">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsgm" :options="jsgmList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item  label="分类">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedLabel" :options="labelList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData1" type="primary" size="mini" round icon="el-icon-upload2">前期进展统计导出</el-button>
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                </span>
                                <h3 class="tit">公路列表</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ xmsl }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj2.png" alt="">
                                        <div class="t">
                                            <h5>建设规模-里程<small>（公里）</small></h5>
                                            <b class="date-num">{{ jsgm }}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-4">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>总投资<small>（万元）</small></h5>
                                            <b class="date-num">{{thousandBitSeparator(ztz.toFixed(0) )}}</b>
                                        </div>
                                    </div>

                                </div>

                                <el-tabs v-model="activeRoad" @tab-click="changeRoad">
                                    <el-tab-pane label="全部" name="全部"></el-tab-pane>
                                    <el-tab-pane label="高速公路" name="高速公路"></el-tab-pane>
                                    <el-tab-pane label="普通国道" name="普通国道"></el-tab-pane>
                                    <el-tab-pane label="普通省道" name="普通省道"></el-tab-pane>
                                </el-tabs>
                                <el-table  @sort-change="changeSort"  @cell-click="cellClick" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <!--                                    <el-table-column prop="SZDS"  label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>-->
                                    <!--                                    <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>-->
                                    <el-table-column prop="XMMC" label="项目名称" align="left" show-overflow-tooltip :width="320"></el-table-column>
                                    <el-table-column prop="LXBH" label="路线编码" align="left" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="XMXZ" label="项目性质" sortable align="center" show-overflow-tooltip :width="150"></el-table-column>
                                    <el-table-column prop="QQGZJZ" label="前期工作进展" :width="400" align="center" show-overflow-tooltip>
                                        <el-table-column prop="JSGMGS" label="可行性研究" width="100" align="right">
                                            <template slot-scope="scope">
                                                <div :style="{color:scope.row.GKBLZT == 0 ? '' : scope.row.GKBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                    {{scope.row.GKBLZT == 0 ? '未开展' : scope.row.GKBLZT == '1' ? '办理中' : '已批复'}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMGS" label="初步设计" width="90" align="right">
                                            <template slot-scope="scope">
                                                <div :style="{color:scope.row.CBSJBLZT == 0 ? '' : scope.row.CBSJBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                    {{scope.row.CBSJBLZT == 0 ? '未开展' : scope.row.CBSJBLZT == '1' ? '办理中' : '已批复'}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMGS" label="施工图设计" width="100" align="right">
                                            <template slot-scope="scope">
                                                <div :style="{color:scope.row.SGTBLZT == 0 ? '' : scope.row.SGTBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                    {{scope.row.SGTBLZT == 0 ? '未开展' : scope.row.SGTBLZT == '1' ? '办理中' : '已批复'}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                    <!--                                    <el-table-column prop="QQGZJZ" label="前期工作进展描述"  align="center" show-overflow-tooltip :width="150"></el-table-column>-->
                                    <el-table-column prop="ZTZ" label="计划总投资(万元)" :width="140" align="center" sortable show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div style="text-align: right">
                                                {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <!--                                    <el-table-column prop="SSWGHXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>-->

                                    <!--                                    <el-table-column prop="JSXZ" label="建设性质"  align="center" show-overflow-tooltip :width="150"></el-table-column>-->


                                    <el-table-column label="建设规模" :width="150"  align="center">
                                        <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(3) : ''}}
                                            </template>
                                        </el-table-column>
                                        <!--                                            <el-table-column label="特大桥" width="50" align="center">-->
                                        <!--                                                <el-table-column prop="JSGMTDQ" label="座数(座)" width="90" align="right"></el-table-column>-->
                                        <!--                                                <el-table-column prop="JSGMTDQYM" label="延米数(米)" width="90" align="right"></el-table-column>-->
                                        <!--                                            </el-table-column>-->
                                        <!--                                        <el-table-column label="隧道" width="50" align="center">-->
                                        <!--                                            <el-table-column prop="JSGMSD" label="座数(座)" width="90" align="right"></el-table-column>-->
                                        <!--                                            <el-table-column prop="JSGMSDYM" label="延米数(米)" width="90" align="right"></el-table-column>-->
                                        <!--                                        </el-table-column>-->
                                    </el-table-column>
                                    <el-table-column label="独立桥隧(延米)" align="center">
                                        <el-table-column prop="JSGMTDQYM" label="桥梁" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMTDQYM ? scope.row.JSGMTDQYM.toFixed(2) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JSGMSDYM" label="隧道" width="90" align="right">
                                            <template slot-scope="scope">
                                                {{scope.row.JSGMSDYM ? scope.row.JSGMSDYM.toFixed(2) : ''}}
                                            </template>
                                        </el-table-column>

                                    </el-table-column>
                                    <el-table-column label="建设年限" align="center">
                                        <el-table-column prop="KGN" label="开工年" :width="120" sortable show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="WGN" label="完工年" :width="120" sortable show-overflow-tooltip></el-table-column>
                                    </el-table-column>
                                    <el-table-column prop="XMFRDW" label="建设单位" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                    <!--                                    <el-table-column prop="QQGZFZR" label="前期工作负责人" :width="150" align="center" show-overflow-tooltip></el-table-column>-->
                                    <!--                                    <el-table-column prop="LXDH" label="联系电话" :width="150" align="center" show-overflow-tooltip></el-table-column>-->
                                    <el-table-column fixed="right" prop="" align="center" :width="95" label="操作">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                        </template>
                                    </el-table-column>

                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-dialog :visible.sync="showAddModel" @close="closeDrawer" width="90%" title="项目信息"  class="editModal">
                <el-row :gutter="20" class="card1">
                    <el-col :span="17">
                        <el-card class="box-card card1">
                            <PlanForm  v-if="dialogTableVisibleMap" :isLock="isLock" :dialogData="dialogTableVisibleData" />
                        </el-card>
                    </el-col>
                    <el-col :span="7">
                        <el-card class="box-card ">
                           <div class="card1" :style="{height:cardheight+'px'}" style="overflow-y: scroll">
                                <div class="xmbt">项目信息</div>
                               <div class="xmtt">
                                   <div class="xmmc">{{detail.xmmc}}</div>
                                   <div class="talL">
                                       <span class="tag" v-if="detail.lxbh">{{detail.lxbh}}</span>
                                       <span class="tag"  v-if="detail.xmxz">{{detail.xmxz}}</span>
                                       <span class="tag">{{detail.qqgllx}}</span>
                                   </div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">法人单位：</div>
                                   <div class="cont">{{detail.xmfrdw}}</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">所在地市：</div>
                                   <div class="cont">{{detail.szds}}</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">所属县(区)：</div>
                                   <div class="cont">{{detail.szx}}</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">开工年：</div>
                                   <div class="cont">{{detail.kgn}}</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">完工年：</div>
                                   <div class="cont">{{detail.wgn}}</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">建设规模：</div>
                                   <div class="cont">{{detail.jsgmhj}} km</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">总投资：</div>
                                   <div class="cont" v-if="detail.ztz">{{thousandBitSeparator(detail.ztz.toFixed(0) )}} 万元</div>
                               </div>
                               <div class="infoWrap">
                                   <div class="infit">项目相关文件：</div>
                                   <el-form  ref="detailForm"  class="form-inline" v-loading="uploadLoading">
                                       <el-form-item>
                                           <div>
                                               <label class="fright">
                                                   <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                   <input accept="application"  style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'xmxgwj')" />
                                               </label>
                                           </div>
                                       </el-form-item>
                                   </el-form>
                               </div>
                               <div class="fileWrap">
                                   <div class="file" v-for="(item,index) in detail.xmxgwj" :key="index">
                                       <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                           <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                           <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                       </a>
                                       <!--                                                </div>-->
                                       <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'xmxgwj')">
                                           <template #reference>
                                               <i style="color: #666666" class="el-icon-delete" ></i>
                                           </template>
                                       </el-popconfirm>
                                   </div>
                               </div>
                               <div>
                               </div>
                           </div>
                        </el-card>
                    </el-col>
                </el-row>
                    <el-row :gutter="20" class="card2">
                        <el-col :span="17">
                            <el-card class="box-card card2" style="margin-top: 12px">
                                <div :style="{height:card2height+'px',overflowY:'scroll'}">
                                    <div class="xmbt">前期进展</div>
                                    <el-table ref="table" :data="listData" size="small "  border :default-sort="{ prop: 'SSDS', order: 'ascending' }"  style="width: 99%;margin-top: 16px">
                                        <el-table-column  prop="jdmc" label="节点名称"  width="150"></el-table-column>
                                        <el-table-column show-overflow-tooltip prop="jhwcsj" width="110" label="计划完成时间">
                                            <template slot-scope="scope">
                                                {{scope.row.jhwcsj ? scope.row.jhwcsj.slice(0,10) : ''}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column show-overflow-tooltip prop="pfsj"  width="120" label="批复时间" ></el-table-column>
                                        <el-table-column show-overflow-tooltip prop="pfwh" label="批复文号"></el-table-column>
                                        <el-table-column  prop="blzt" label="办理状态" align="center">
                                            <template slot-scope="scope">
                                                {{scope.row.blzt == 0 ? '未开展' : scope.row.blzt == 1 ? '办理中' : '已办结'}}

                                            </template>
                                        </el-table-column>
                                        <el-table-column  prop="name" label="附件" align="center">
                                            <template slot-scope="scope">
                                                <el-popover trigger="hover" placement="top" v-if="scope.row.fj.length > 0">
                                                    <ul class="fj-list">
                                                        <li v-for="(item,i) in scope.row.fj" :key="i">
                                                            <a target="view_window" :href="baseUrl+item.url+item.name">{{item.name}}</a>
                                                            <!--                                        <i @click.stop="deleted(scope.row,i)" class="el-icon-delete" style="color: #dd6161;margin-left: 3px"></i>-->
                                                            <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                                        </li>
                                                    </ul>

                                                    <div slot="reference" class="name-wrapper">
                                                        <el-tag :type="scope.row.fj.length>0?'success':'danger'" size="small">已上传{{scope.row.fj.length}}个</el-tag>
                                                    </div>
                                                </el-popover>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="7">
                            <el-card class="box-card card2" style="margin-top: 12px">
                                <div :style="{height:card2height+'px',overflowY: 'scroll'}">
                                    <div>
                                        <div class="xmbt">
                                            <div>计划下达</div>
<!--                                            <div class="xman" @click="toJH">查看详情</div>-->
                                        </div>
                                    </div>
                                        <div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: space-around;">
                                            <div class="jht">计划总投资 <span class="jhn">{{dnjhtzhj}}万元</span></div>
                                            <div class="jht">计划建设规模 <span class="jhn">{{TZJSGMHJ}}公里</span></div>
                                        </div>
                                       <div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: space-around;margin-top: 10px">
                                           <div class="xdzj">
                                               <div class="xdt">累计下达资金合计</div>
                                               <div class="xdq">{{ljxdhj.NJHTZHJ}}万元</div>
                                           </div>
                                           <div class="xdzj">
                                               <div class="xdt">累计下达中央投资</div>
                                               <div class="xdq">{{ljxdhj.NJHTZZYTZ}}万元</div>
                                           </div>
                                       </div>
                                    <table class="littleTable">
                                        <tr>
                                            <td style="background: #f5f7fa;">年度批次</td>
                                            <td style="background: #f5f7fa;">合计</td>
                                            <td style="background: #f5f7fa;">中央投资(车购税)</td>
                                        </tr>
                                        <tr v-for="(item,index) in ljxdtzlist" :key="index">
                                            <td class="label2">{{item.JHND+'-'+item.JHPC}}</td>
                                            <td class="label2">{{item.NJHTZHJ}}</td>
                                            <td class="label2">{{item.NJHTZZYTZ}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
<!--                <div class="detailWrap">-->
<!--                    <el-row :gutter="20">-->
<!--                        <el-col :span="16">-->
<!--                            <el-card class="box-card card1 el-col-8">-->
<!--                                <div slot="header" class="clearfix" >-->
<!--                                    <span>卡片名称</span>-->
<!--                                </div>-->
<!--                                <div v-for="o in 4" :key="o" class="text item">-->
<!--                                    前期-->
<!--                                </div>-->
<!--                            </el-card>-->
<!--                        </el-col>-->
<!--                        <el-col :span="8">-->
<!--                            <el-card class="box-card card2">-->
<!--                                <div slot="header" class="clearfix">-->
<!--                                    <span>卡片名称</span>-->
<!--                                    <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
<!--                                </div>-->
<!--                                <div v-for="o in 4" :key="o" class="text item">-->
<!--                                    计划-->
<!--                                </div>-->
<!--                            </el-card>-->
<!--                        </el-col>-->
<!--                    </el-row>-->

<!--                </div>-->
            </el-dialog>

        </div>
    </div>
</template>
<script>
    import PlanForm from "../proInfoMap/PlanForm";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    export default {
        name: "proInfo",
        components: {PlanForm},
        // components: {FlowTable},
        data() {
            return {
                ljxdhj:{
                    NJHTZSSXZC:0,
                    NJHTZHJ:0,
                    NJHTZZYTZ:0,
                    NJHTZCZZJ:0,
                    NJHTZCPYSF:0,
                    NJHTZZXZQ:0,
                    NJHTZYHDK:0,
                    NJHTZZQYZC:0,
                    NJHTZDFZC:0,
                },
                ljxdtzlist:[],
                cardheight:(document.documentElement.clientHeight - 80)*0.6,
                card2height:(document.documentElement.clientHeight - 80)*0.3,
                saveLoading: false,
                labelList:[],
                checkedLabel: [], //标签分类
                xmsl:'0',
                jsgm:'0',
                ztz:0,
                sswtz:0,
                activeRoad: '全部',
                isLock: false, //fasle 未锁定  true 已锁定
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                activeName:'0',
                required: false,
                innerDrawer: false,
                title:'新增建设单位',
                ruleForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                XMFRDW:'',
                XMFRDWID:'',
                frCompany:false,
                frCompanyList:[],
                frdw:'',
                frForm:{},
                formInline:{},
                assign: false,
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                },
                showDetail: false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                gkbz:false,
                singleData:{
                    wlyd:0,
                    nyd:0
                },
                XMMC:'',
                addStatus: true,
                Collapse:['1','2','3'],
                step:['一、工可','二、初步设计','三、施工图设计','四、施工前准备','五、县域国土空间规划'],
                activeProcess:0,
                showEditModel: false,
                confirmSSW:[],
                tableHeight:0, //表格高度
                activeIndex: true, //显隐高级搜索
                /**查询条件 begin*/
                xmmc: "", //项目名称
                xmnd: "", //项目年度
                gllx: "", //建设类别
                checkedXmxz: [], //项目性质
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                checkedXmlb: [], //项目类别
                checkedJsgm: [], //项目类别
                checkedGk: [], //项目类别
                checkedSgtsj: [], //项目类别
                checkedCbsj: [], //项目类别
                region: [""], //已选中行政区划
                qqgzjz: [], //前期工作进展
                /**查询条件 end*/
                xmxzShow: true, //项目性质查询条件显隐
                jsxzShow: true, //建设性质查询条件显隐
                xmlxShow: true, //项目类型查询条件显隐
                checkList: [],
                checkAllXmlb: false,
                checkAllXmxz: false,
                checkAllJsxz: false,
                checkAllXmlx: false,
                checkAllJsgm: false,
                checkAllGk: false,
                checkAllCbsj: false,
                checkAllSgtsj: false,
                xmxzList: [
                    {
                        value: "续建项目",
                        label: "续建项目",
                        disabled: false,
                    },
                    {
                        value: "新开工项目",
                        label: "新开工项目",
                        disabled: false,
                    },
                    {
                        value: "谋划项目",
                        label: "谋划项目",
                        disabled: false,
                    }
                ],
                jsxzList: [
                    { value: "新建", label: "新建", disabled: false },
                    { value: "升级改造", label: "升级改造", disabled: false },
                    { value: "原级改造", label: "原级改造", disabled: false },
                    { value: "路面改造", label: "路面改造", disabled: false },
                    { value: "改扩建", label: "改扩建", disabled: false },
                ],
                xmlxList: ["正选项目", "备选项目"],
                jsgmList: [{value: "JSGMGS", label: "高速", disabled: false},{value: "JSGMYJ", label: "一级", disabled: false},{value: "JSGMEJ", label: "二级", disabled: false},{value: "JSGMSJ", label: "三级", disabled: false},{value: "JSGMTDQ", label: "特大桥", disabled: false},{value: "JSGMSD", label: "隧道", disabled: false}],
                xmlbList: [{value: "审批", label: "审批", disabled: false},{value: "核准", label: "核准", disabled: false}],
                gkList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                cbsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                sgtsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                isIndeterminateXmxz: true,
                isIndeterminateJsgm: true,
                isIndeterminateGk: true,
                isIndeterminateCbsj: true,
                isIndeterminateSgtsj: true,
                isIndeterminateJsxz: true,
                isIndeterminateXmlx: true,
                isIndeterminateXmlb: true,
                SSWSSWGHXMID:'',
                regions: [],
                tableData: [], //请求列表
                total: 0, //请求记录数
                total1: 0, //请求记录数
                totalSqu:0,
                companyData:[],
                searchAll:[],
                chek:[],
                newAdd:false,
                editModel:{},
                showEdit:false,
                qqjzid:'',
                innerDrawerTitle:'',
                gg: '',
                GKWCD:'',
                CBSJWCD:'',
                SGTSJWCD:'',
                SGQZBWCD:'',
                isAdmin: false,
                rowData:{},
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                saveTag:[],
                listData:[],
                jhData:{},
                TZJSGMHJ:0,
                dnjhtzhj:0,
            };
        },
        mounted() {
            var that = this
            this.getTagList()
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 255;
            }, 100);
            this.getRegion();
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        computed:{

        },
        methods: {
            //附件处理
            dealFj(data){
                // console.log(data)
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }
            },
            dealSaveFj(data){
                console.log(data);
                if(data == null){
                    return ''
                }
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                // console.log( pfwjString.join(';'),456)
                return pfwjString.join(';')
            },
            //去计划填报
            toJH(){
                this.showAddModel = false;
                localStorage.setItem('xmmc',this.detail.xmmc)
                this.$router.push('/Road/investment')
            },
            //查计划
            getPlan(xmmc){
                this.http.post('/api/Plan_filling/GetData',{
                    wheres: JSON.stringify([{
                            Name: "QQMC",
                            Value: xmmc,
                            DisplayType: "like",
                        }])}).then(res=>{
                    console.log(res.rows)
                    if(res.rows.length !== 0){
                        this.jhData = res.rows[0]
                        this.dnjhtzhj = res.rows[0].JHZZJHJ
                        this.TZJSGMHJ = res.rows[0].JSGMHJ
                    }else{
                        this.jhData = {}
                    }
                })
            },
            sortByKey(array,key,type){
                return array.sort(function(a,b){
                    var x = parseFloat(a[key]);
                    var y = parseFloat(b[key]);
                    if(type == 'desc'){
                        return((x>y)?-1:((x<y)?1:0));
                    }else{
                        return((x<y)?-1:((x>y)?1:0));
                    }
                })
            },
            //查历史计划
            getHistory(id){
                this.http.post('/api/Plan_filling_history/GetItemByQQID?id='+id, {}).then(res=> {
                    // if (JSON.stringify(this.jhData) !== '{}'){
                    //     res.push(this.jhData)
                    // }
                    if(res.length>0){
                        let lastdata = res[res.length-1]
                        // this.detail.LJWCTZHJ = lastdata.LJWCTZ;//添加累计完成投资
                        // this.detail.LJWCTZZYTZ=lastdata.LJWCZYCGS;

                        this.detail.LJWCTZCPYSF = lastdata.LJWCTZCPYSF
                        this.detail.LJWCTZCZZJ = lastdata.LJWCTZCZZJ
                        this.detail.LJWCTZDFZC = lastdata.LJWCTZDFZC
                        this.detail.LJWCTZHJ = lastdata.LJWCTZHJ
                        this.detail.LJWCTZJSNR = lastdata.LJWCTZJSNR
                        this.detail.LJWCTZQYZC = lastdata.LJWCTZQYZC
                        this.detail.LJWCTZXZSCNL = lastdata.LJWCTZXZSCNL
                        this.detail.LJWCTZYHDK = lastdata.LJWCTZYHDK
                        this.detail.LJWCTZZXZQ = lastdata.LJWCTZZXZQ
                        this.detail.LJWCTZZYTZ = lastdata.LJWCTZZYTZ
                        this.dnjhtzhj = res[0].JHZZJHJ
                        this.TZJSGMHJ = res[0].JSGMHJ
                        this.setZjValue(res)
                    }
                    this.ljxdtzlist = this.sortByKey(res,"XH",'asc')
                    console.log(this.ljxdtzlist,'this.ljxdtzlist')
                    if(this.ljxdtzlist.length >0){
                        this.showAllTZ = false
                    }else{
                        this.showAllTZ = true
                    }
                })
            },
            deleted(index,type){
                if (this.saveLoading){
                    return false
                }
                var that = this
                this.detail[type].splice(index,1)
                var params = JSON.parse(JSON.stringify(that.detail))
                params.xmxgwj = this.dealSaveFj(params.xmxgwj)
                console.log(params)
                this.http.post('/api/Plan_high_national_early/UpdatePlanHighNationalEarly',params).then(res=>{
                    this.saveLoading = false
                    if(res.status){
                        this.detail = res.data
                        this.detail.xmxgwj = this.dealFj(res.data.xmxgwj)``
                        this.$message.success(res.message)
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            setNumDefault(nums){
                if(!nums) return 0;
                return parseFloat(nums);
            },
            moneyToNumFiled(money){
                if(money == 0 || !money) return 0;
                return money;
            },
            Subtr(arg1,arg2){
                var r1,r2,m,n;
                try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
                try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
                m=Math.pow(10,Math.max(r1,r2));
                n=(r1>=r2)?r1:r2;
                console.log(n)
                return ((arg1*m-arg2*m)/m);
            },
            setZjValue(res){
                var hj = 0;
                var cgs = 0;
                var cz = 0;
                var cpy = 0;
                var zx = 0;
                var yh = 0;
                var qy = 0;
                var df = 0;
                var zc = 0;
                res.map(r=>{
                    console.log(r.NJHTZHJ,'r.NJHTZHJ')
                    hj+=this.moneyToNumFiled(r.NJHTZHJ||0);
                    cgs+=this.moneyToNumFiled(r.NJHTZZYTZ||0);
                    cz+=this.moneyToNumFiled(r.NJHTZCZZJ||0);
                    cpy+=this.moneyToNumFiled(r.NJHTZCPYSF||0);
                    zx+=this.moneyToNumFiled(r.NJHTZZXZQ||0);
                    yh+=this.moneyToNumFiled(r.NJHTZYHDK||0);
                    qy+=this.moneyToNumFiled(r.NJHTZZQYZC||0);
                    zc+=this.moneyToNumFiled(r.NJHTZSSXZC||0);
                })

                this.ljxdhj.NJHTZHJ = hj;
                this.ljxdhj.NJHTZZYTZ = cgs;
                this.ljxdhj.NJHTZCZZJ = cz;
                this.ljxdhj.NJHTZCPYSF = cpy;
                this.ljxdhj.NJHTZZXZQ = zx;
                this.ljxdhj.NJHTZSSXZC = zc;
                this.ljxdhj.NJHTZYHDK = yh;
                this.ljxdhj.NJHTZZQYZC = qy;
                this.ljxdhj.NJHTZDFZC = df;

                this.detail.SYZJHJ = parseFloat(this.detail.JHZZJHJ||0)-hj-parseFloat(this.detail.NJHTZHJ||0);
                this.detail.SYZJZYTZ =  this.setNumDefault(this.detail.JHZZJZYTZ)-cgs-this.setNumDefault(this.detail.NJHTZZYTZ);
                this.detail.SYZJCZZJ = this.setNumDefault(this.detail.JHZZJCZZJ)-cz-this.setNumDefault(this.detail.NJHTZCZZJ);
                this.detail.SYZJCPYSF = this.setNumDefault(this.detail.JHZZJCPYSF)-cpy-this.setNumDefault(this.detail.NJHTZCPYSF);
                this.detail.SYZJZXZQ = this.setNumDefault(this.detail.JHZZJZXZQ)-zx-this.setNumDefault(this.detail.NJHTZZXZQ);
                this.detail.SYZJSSXZC =this.Subtr(this.Subtr(this.setNumDefault(this.detail.JHZZJSSXZC),zc),this.setNumDefault(this.detail.NJHTZSSXZC));
                this.detail.SYZJYHDK = this.setNumDefault(this.detail.JHZZJYHDK)-yh-this.setNumDefault(this.detail.NJHTZYHDK);
                this.detail.SYZJZQYZC = this.setNumDefault(this.detail.JHZZJQYZC)-qy-this.setNumDefault(this.detail.NJHTZZQYZC);
                this.detail.SYZJDFZC = this.setNumDefault(this.detail.JHZZJDFZC)-df-this.setNumDefault(this.detail.NJHTZDFZC);

                if(this.detail.JHZZJDFZC == null){
                    this.detail.JHZZJDFZC  =df
                }
            },
            getData(id){
                var that = this
                this.http.get('/api/Plan_high_national_early_node/GetAllNode?id='+id,).then(res=>{
                    res.data.map((item,index)=>{
                        var a = []
                        if(item.fj != '' && item.fj != null){
                            item.fj = item.fj.split(';')
                            item.fj.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.fj = a
                        }else {
                            item.fj = []
                        }
                    })
                    this.listData.push(res.data[4],res.data[10],res.data[12])
                })
            },
            getTagList(){
                var that = this
                this.http.post('/api/Land_road_tag_base/GetPageData',{page:1,rows:1000,}).then(res=>{
                    console.log(res)
                    this.labelList = res.rows
                    this.labelList.map((item,index)=>{
                        item.label = item.BQWZ
                        item.value = item.ID
                        item.disabled = false
                    })
                    that.$nextTick(() => {
                        res.rows.forEach((item, index) => {
                            // if (!item.canChoose) {
                            //     // 默认被选中且禁用
                            //     this.$refs.multipleTable.toggleRowSelection(item, true);  // 如果只需要禁用 不需要这行
                            // }
                            //  selectReady 是默认要选中的数据
                            if (that.saveTag.length > 0) {
                                that.saveTag.forEach((obj, key) => {
                                    if (item.ID == obj.TagId) { // 如果匹配上  代表当前角色下的用户  高亮选中
                                        console.log('22222222222')
                                        that.$refs.multipleTables.toggleRowSelection(item);
                                    }
                                })
                            }
                        })
                        // this.chek = rows.TagList

                    })

                })
            },
            cellClick(row, column, cell, event){
                if (column.label != '操作'){
                    this.openDetailsEdit(row)
                }
            },
            getPageTotal() {
                this.http
                    .post("/api/Plan_high_national_early/GetPageTotal", this.postData())
                    .then((res) => {
                        this.xmsl = res.xmsl;
                        this.jsgm = res.jsgm;
                        this.jsqmqs = res.jsqmqs;
                        this.ztz = res.ztz;
                        this.sswtz = res.sswtz;
                    });
            },
            changeRoad(tab, event){
                this.currentPage = 1
                if(tab.label == '全部'){
                    this.gllx = ''
                }else{
                    this.gllx = tab.label
                }
                this.search()
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },

            closeDrawer(){
                this.listData = []
                this.dialogTableVisibleMap = false;
                this.search()
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            //选择建设单位
            async selectCompany1(val){
                if(val.length > 1){
                    this.$refs.multipleTable1.clearSelection()
                    this.$refs.multipleTable1.toggleRowSelection(val.pop())
                }else{
                    this.chekfr = val;
                }
            },

            changeTotal(val,type){
                var that = this
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                if (val && !reg.test(val)) {
                    return false
                }
                if (type == 'nyd'){
                    if(this.detail.ydwlyd != '' && this.detail.ydwlyd != null){
                        this.totalSqu = val == '' ? that.accAdd(0,parseFloat(this.detail.ydwlyd))  :  that.accAdd( parseFloat(val) ,  parseFloat(this.detail.ydwlyd))
                    }else{
                        this.totalSqu = val == '' ? 0 : parseFloat(val)*1000/1000
                    }
                }else{
                    if(this.detail.ydnyd != '' && this.detail.ydnyd != null){
                        this.totalSqu =  val == '' ? that.accAdd(0 , parseFloat(this.detail.ydnyd)) : that.accAdd(parseFloat(val)  , parseFloat(this.detail.ydnyd))
                    }else{
                        this.totalSqu = val == '' ? 0: parseFloat(val)
                    }
                }

            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            onSubmit(e, type) {
                console.log(e,type)
                var that = this
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail.xmxgwj.push({url:res.data, name:v})
                        });
                        console.log(this.detail.xmxgwj)
                        var params = JSON.parse(JSON.stringify(that.detail))
                        params.xmxgwj = this.dealSaveFj(params.xmxgwj)
                        params.jsxz = params.jsxz.join(";")
                        console.log(params)
                        this.http.post('/api/Plan_high_national_early/UpdatePlanHighNationalEarly',params).then(res=>{
                            this.saveLoading = false
                            if(res.status){
                                this.$message.success(res.message)
                            }else{
                                this.$message.error(res.message)
                            }
                        })
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            changeCollapse(val){
            },
            chechfieldxmlx() {
                return this.gllx == "普通省道" ? false : true;
            },
            chechfieldxmlb() {
                return this.gllx == "普通省道" ? false : true;
            },
            checkfieldjsxz() {
                return this.gllx == "高速公路" ? true : false;
            },
            checkfieldxmxz(e) {
                var arr = [];
                if (this.gllx == "高速公路") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年新开工项目",
                        "“十四五”其他项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通国道") {
                    arr = [
                        "“十三五”续建项目",
                        "“十四五”新开工项目",
                        "谋划项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通省道") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年已安排计划项目",
                        "2022年建设项目",
                        "2023年建设项目",
                        "2024-2025年建设项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
            },
            closeDetail(){
                this.showDetail = false
                this.detail = {}

            },
            openDetailsEdit(row) {
                console.log(row)
                var that = this
                this.showAddModel = true
                this.activeName = '0'
                this.isLock = row.IsLock
                this.getData(row.ID)
                this.getHistory(row.ID)
                this.getPlan(row.XMMC)
                this.http.post('/api/Land_road_flag_early/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.EarlyID = res.EarlyID ? res.EarlyID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = JSON.parse(JSON.stringify(row));
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
                // this.$refs.detailForm.resetFields();
                this.addStatus = false
                setTimeout(()=>{
                    this.qqjzid = row.ID
                    this.gg =  row.XMSX !=null &&  row.XMSX == '国高'? true : false
                },500)
                this.http.post('/api/Plan_high_national_early/getItemById?id='+row.ID,{}).then(res=>{
                    this.detail = res.data
                    this.detail.xmxgwj = this.dealFj(res.data.xmxgwj)
                    // this.totalSqu = res.data.ydhj
                    // this.XMMC = res.data.sswghxmmc
                    // this.SSWSSWGHXMID = res.data.sswghxmid
                    // this.XMFRDW = res.data.xmfrdw
                    // this.XMFRDWID = res.data.xmfrdwid

                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/templateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            exportData1() {
                var that = this
                this.http.post('/api/Plan_high_national_early/EarlyTemplateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期进展统计表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.getPageTotal(); //查汇总
                this.http
                    .post(
                        "/api/Plan_high_national_early/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postData(gllx) {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //项目gx
                var query_gllx = {
                    Name: "QQGLLX",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
                //法人单位
                var query_frdw = {
                    Name: "XMFRDW",
                    Value: this.frdw,
                    DisplayType: "like",
                };
                //所属地市
                var query_ssds = {
                    Name: "SZDS",
                    Value: szds.indexOf('市')!== -1 ?szds.replace('市','') : szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx.indexOf('市')!== -1 ?ssx.replace('市','') : ssx.indexOf('区')!== -1 ? ssx.replace('区','') :ssx.indexOf('县')!== -1 ?  ssx.replace('县','') : ssx,
                    DisplayType: "like",
                };
                //项目类别
                var xmlbArray = [];
                this.checkedXmlb.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmlb = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };
                //建设性质
                var jsxzArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsxzArray.push(element);
                });
                var query_jsxz = {
                    Name: "JSXZ",
                    Value: jsxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目性质
                var xmxzArray = [];
                this.checkedXmxz.forEach((element) => {
                    xmxzArray.push(element);
                });
                var query_xmxz = {
                    Name: "XMXZ",
                    Value: xmxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //建设规模
                var jsgmArray = [];
                this.checkedJsgm.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "JSGMZT",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
//工可
                var gk = [];
                this.checkedGk.forEach((element) => {
                    gk.push(element);
                });
                var query_gk = {
                    Name: "GKBLZT",
                    Value: gk.join(","),
                    DisplayType: "checkbox",
                };
                var query_xmbq = {
                    Name: "TagId",
                    Value: this.checkedLabel.join(","),
                    DisplayType: "checkbox",
                };
                var cbsj = []
                this.checkedCbsj.forEach((element) => {
                    cbsj.push(element);
                });
                var query_cbsj = {
                    Name: "CBSJBLZT",
                    Value: cbsj.join(","),
                    DisplayType: "checkbox",
                };

                var sgt = []
                this.checkedSgtsj.forEach((element) => {
                    sgt.push(element);
                });
                var query_sgt = {
                    Name: "SGTBLZT",
                    Value: sgt.join(","),
                    DisplayType: "checkbox",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "NoSort",
                    Order: "",
                    wheres: JSON.stringify([
                        query_xmbq,
                        query_jsxz,
                        query_xmxz,
                        query_gllx,
                        query_frdw,
                        query_xmlb,
                        query_xmmc,
                        query_ssds,
                        query_ssx,
                        query_gk,
                        query_sgt,
                        query_cbsj,
                        query_jsgm

                    ]),
                };
                return postData;
            },
            postData1(page){

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.formInline.XMMC,
                    DisplayType: "like",
                };

                if(page){
                    this.currentPage1 = page
                }
                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
                this.checkedXmxz = [];
                this.checkedJsxz = [];
            },
            // handleChange(value) {
            //     this.checkedXmxz = [];
            //     this.search();
            // },
            handleCheckAllJsxzChange(val) {
                this.checkedJsxz = val ? this.jsxzList : [];
                this.isIndeterminateJsxz = false;
            },
            //项目类别
            handleCheckAllXmlbChange(val){
                this.checkedXmlb = val ? this.xmlbList : [];
                this.isIndeterminateXmlb = false;
            },
            //建设规模
            handleCheckAllJsgmChange(val){
                this.checkedJsgm = val ? this.jsgmList : [];
                this.isIndeterminateJsgm = false;
            },
            //工可
            handleCheckAllGkChange(val){
                this.checkedGk = val ? this.gkList : [];
                this.isIndeterminateGk = false;
            },
            //初步设计
            handleCheckAllCbsjChange(val){
                this.checkedCbsj = val ? this.cbsjList : [];
                this.isIndeterminateCbsj = false;
            },
            //施工图设计
            handleCheckAllSgtsjChange(val){
                this.checkedSgtsj = val ? this.sgtsjList : [];
                this.isIndeterminateSgtsj = false;
            },
            handleCheckAllXmlxChange(val) {
                if (this.gllx == "普通省道") {
                    this.checkedXmlx = val ? this.xmlxList : [];
                }
                this.isIndeterminateXmlx = false;
            },
            handleCheckedXmxzChange(value) {
                let checkedCount = value.length;
                this.checkAllXmxz = checkedCount === this.xmxzList.length;
                this.isIndeterminateXmxz =
                    checkedCount > 0 && checkedCount < this.xmxzList.length;
            },
            handleCheckedJsxzChange(value) {
                let checkedCount = value.length;
                this.checkAllJsxz = checkedCount === this.jsxzList.length;
                this.isIndeterminateJsxz =
                    checkedCount > 0 && checkedCount < this.jsxzList.length;
            },
            handleCheckedXmlbChange(value){
                let checkedCount = value.length;
                this.checkAllXmlb = checkedCount === this.xmlbList.length;
                this.isIndeterminateXmlb =
                    checkedCount > 0 && checkedCount < this.xmlbList.length;
            },
            handleCheckedJsgmChange(value){
                let checkedCount = value.length;
                this.checkAllJsgm = checkedCount === this.jsgmList.length;
                this.isIndeterminateJsgm =
                    checkedCount > 0 && checkedCount < this.jsgmList.length;
            },
            handleCheckedXmlxChange(value) {
                let checkedCount = value.length;
                this.checkAllXmlx = checkedCount === this.xmlxList.length;
                this.isIndeterminateXmlx =
                    checkedCount > 0 && checkedCount < this.xmlxList.length;
            },
            handleCheckedGkChange(value) {
                let checkedCount = value.length;
                this.checkAllGk = checkedCount === this.gkList.length;
                this.isIndeterminateGk =
                    checkedCount > 0 && checkedCount < this.gkList.length;
            },
            handleCheckedCbsjChange(value) {
                let checkedCount = value.length;
                this.checkAllCbsj = checkedCount === this.cbsjList.length;
                this.isIndeterminateCbsj =
                    checkedCount > 0 && checkedCount < this.cbsjList.length;
            },
            handleCheckedSgtsjChange(value) {
                let checkedCount = value.length;
                this.checkAllSgtsj = checkedCount === this.sgtsjList.length;
                this.isIndeterminateSgtsj =
                    checkedCount > 0 && checkedCount < this.sgtsjList.length;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /*/deep/.el-collapse-item__content {*/
        /*    padding:10px 15px 20px 15px  !important;*/
        /*}*/
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
                height: 35px;
                line-height: 35px;
            }

        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 5px;
        .dwmc{
            padding-left: 15px;
            text-align: left;
            width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 35px;
            background: #ffffff;
            border-radius: 5px 0 0 5px;
            line-height: 35px;

        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 35px;
            background: #fafafa;
            border-radius: 0 5px 5px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    /deep/.el-dialog{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 0;
            width: 330px;
            background: #F2F8FE;
            height: 1000px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
    .detailWrap{
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
    }
    .card1{
        height: 60%;
    }
    .xmbt{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(112, 112, 112, .2);
        color: #008FD4;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        position: relative;
        height: 30px;
        .xman{
            cursor: pointer;
            margin-right: 12px;
            font-size: 12px;
            font-weight: normal;
        }
        &::before{
            content:'';
            width: 45px;
            height: 4px;
            border-radius: 4px;
            background:#008FD4 ;
            position: absolute;
            bottom: 0;
            left: 10px;
        }
    }
    .el-card__body{
        padding: 12px !important;
    }
    .xmtt{
        margin-top: 15px;
        padding: 8px 12px;
        background: rgba(248,248,248);
        border-radius: 10px;
        .xmmc{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
        }
        .talL{
            margin-top: 10px;
            display: flex;
            align-items: flex-start;
            flex-grow: 1;
            .tag{
                margin-right: 10px;
                border-radius: 4px;
                padding: 3px 9px;
                background: rgba(228,232,251,0.39);
                color: #008FD4;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
            }

        }
    }
    .infoWrap{
        display: flex;
        align-items: center;
        font-family: Microsoft YaHei UI;
        .infit{
            width: 88px;
            font-size: 14px;
            color: #666666;
        }
        .cont{
            font-size: 14px;
            line-height: 32px;
            color: #000000;
        }
    }
    .jht{
        margin-top: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        display: flex;
        align-items: center;
        .jhn{
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #008FD4;
        }
    }
    .xdzj{
        width: 45%;
        height: 78px;
        background: rgba(231,244,254,0.39);
        border-radius: 10px;
        font-family: Microsoft YaHei;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .xdt{
            font-size: 16px;
            font-weight: 400;
            color: #000000;
        }
        .xdq{
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: #000000;
        }
    }
    .littleTable{
        margin-top: 10px;
        width: 100%;
        border:1px solid rgba(0,0,0,.2);
        border-collapse:collapse;
        tr{
            text-align: center;
            width: 33%;
        }
        td{
            width: 33%;
        }
        tr td{ border-bottom:1px solid rgba(0,0,0,.2); border-right:1px solid rgba(0,0,0,.2);line-height:23px; empty-cells:show}

        .label2{
            height: 30px;
            line-height: 30px;
        }
    }
</style>
